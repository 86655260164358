import React from 'react';

import { loadCSS } from '../../../../../utils/loadCSS';
import { Consumer } from '../../HawkWidget/HawkWidgetProviderContext';

import SeasonalEventMerchant from './SeasonalEventMerchant';
import styles from './styles/seasonal-event-merchants.css';

interface SeasonalEventMerchantsProps {
  className?: string;
}

loadCSS('seasonal-event-merchants');

const SeasonalEventMerchants: React.FC<SeasonalEventMerchantsProps> = ({ className }) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const { translate } = value;
      let { data } = value;
      data = data || {};
      const { event } = data;
      const containerStyle = className && styles[className] ? className : 'container';

      if (event && event.merchants && event.merchants.length > 0) {
        return (
          <div className={styles[containerStyle]}>
            <div className={styles.description}>
              <span
                {...{
                  dangerouslySetInnerHTML: {
                    __html: translate('seasonalEventMerchantsLabel', [
                      '<b>',
                      event.event_name,
                      '</b>',
                    ]),
                  },
                }}
              />
            </div>
            <div className={styles.merchants}>
              {(event.merchants || []).map((merchant) => (
                <SeasonalEventMerchant
                  merchant={merchant}
                  key={merchant.merchant_id}
                  className={className}
                />
              ))}
            </div>
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default SeasonalEventMerchants;
